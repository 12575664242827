import * as React from 'react';
import './index.css';
import logo from '../images/logo.png';
import Waves from '../components/Waves/Waves';
import Tick from '../components/Tick/Tick';

// markup
const SuccessPage = () => {
  const [tickClass, setTickClass] = React.useState('');

  React.useEffect(() => {
    setTimeout(() => {
      setTickClass('drawn');
    }, 100);
  }, []);

  return (
    <main>
      <title>Ripply - AI Driven Advertising</title>
      
      <div className="centre-form">
        <img className="logo-main" src={logo} alt="Ripply" />
        <p class="catch">Smarter Ads</p>

        <form noValidate autoComplete="off" className="success">
          <Tick tickClass={tickClass} />

          <h3>Thanks for your enquiry. <br/> We'll be in touch.</h3>
        </form>
      </div>

      <Waves />
    </main>
  )
}

export default SuccessPage
